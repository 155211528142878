import * as React from "react";
import { useLocation } from "@reach/router";
import { clsx } from "clsx";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import SearchResults from "../components/SearchResults/SearchResults";
import SearchResult from "../components/SearchResult/SearchResult";
import Button from "../components/Button/Button";
import SearchResultCarousel from "../components/SearchResultCarousel/SearchResultCarousel";
import FeaturedIn from "../components/FeaturedIn/FeaturedIn";
import Footer from "../components/Footer/Footer";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";

import "./ActivityPage.css";
import {
  getCategoriesByRegion as apiGetCategoriesByRegion,
  getActivitiesByRegion as apiGetActivitiesByRegion,
  getActivitiesByCountry as apiGetActivitiesByCountry,
} from "../services/api";

import { GlobalStateContext } from "../context/GlobalContextProvider";

const createSlug = (title) => {
  const slug = title.replace(/\W+/g, "-").toLowerCase();
  return slug;
};

const RegionPage = ({ location, pageContext }) => {
  const isFirstRender = React.useRef(true);

  const context = React.useContext(GlobalStateContext);

  const params = new URLSearchParams(location.search);
  const category = params.get("category");

  const [expanded, setExpanded] = React.useState(false);

  const [regionActivities, setRegionActivities] = React.useState([]);
  const [loadingRegionActivities, setLoadingRegionActivities] =
    React.useState(false);
  const [countryActivities, setCountryActivities] = React.useState([]);
  const [loadingCountryActivities, setLoadingCountryActivities] =
    React.useState(false);
  const [foodTourExperienceActivities, setFoodTourExperienceActivities] =
    React.useState([]);
  const [
    loadingFoodTourExperienceActivities,
    setLoadingFoodTourExperienceActivities,
  ] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [loadingCategories, setLoadingCategories] = React.useState(false);

  const [selectedRootCategory, setSelectedRootCategory] = React.useState(null);
  const [filterCategories, setFilterCategories] = React.useState(
    category ? [category] : []
  );
  const [minPrice, setMinPrice] = React.useState(0);
  const [maxPrice, setMaxPrice] = React.useState(450);

  const { region, rootCategories } = pageContext;

  let reloadTimerId = null;

  const getCategoriesByRegion = async () => {
    // setErrors([]);

    if (!context.currentCurrency) {
      return null;
    }

    try {
      setLoadingCategories(true);

      const data = await apiGetCategoriesByRegion(
        region.id,
        context.currentCurrency,
        minPrice,
        maxPrice,
        searchTerm
      );

      if (data) {
        setCategories(data);
      }
      setLoadingCategories(false);
    } catch (e) {
      console.log(e);
      setLoadingCategories(false);
    }
  };

  const getActivitiesByRegion = async () => {
    // setErrors([]);

    if (!context.currentCurrency) {
      return null;
    }

    try {
      setLoadingRegionActivities(true);

      const filterCategoriesStr = filterCategories
        .map((cat) => encodeURIComponent(cat))
        .join(",");

      const data = await apiGetActivitiesByRegion(
        region.id,
        context.currentCurrency,
        filterCategoriesStr,
        minPrice,
        maxPrice,
        searchTerm
      );

      if (data) {
        setRegionActivities(data);
      }
      setLoadingRegionActivities(false);
    } catch (e) {
      console.log(e);
      setLoadingRegionActivities(false);
    }
  };

  const getActivitiesByCountry = async () => {
    // setErrors([]);

    if (!context.currentCurrency) {
      return null;
    }

    try {
      setLoadingCountryActivities(true);

      const data = await apiGetActivitiesByCountry(
        region.countryId,
        context.currentCurrency,
        "",
        0,
        450,
        ""
      );

      if (data) {
        setCountryActivities(data);
      }
      setLoadingCountryActivities(false);
    } catch (e) {
      console.log(e);
      setLoadingCountryActivities(false);
    }
  };

  React.useEffect(() => {
    getActivitiesByRegion();
  }, [context.currentCurrency, filterCategories]);

  React.useEffect(() => {
    if (isFirstRender.current) {
      // Set isFirstRender to false after the first render
      isFirstRender.current = false;
      return;
    }

    const handleTimeout = () => {
      getActivitiesByRegion();
      getCategoriesByRegion();
      reloadTimerId = null;
    };

    if (reloadTimerId) {
      clearTimeout(reloadTimerId);
    }

    reloadTimerId = setTimeout(handleTimeout, 750);

    return () => {
      clearTimeout(reloadTimerId);
    };
  }, [searchTerm, minPrice, maxPrice]);

  React.useEffect(() => {
    getCategoriesByRegion();
  }, [context.currentCurrency]);

  React.useEffect(() => {
    getActivitiesByCountry();
  }, [context.currentCurrency]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [loadingCategories, loadingRegionActivities]);

  const countryPath = `/${createSlug(region.country.name)}`;

  let regionPath = "";

  if (region) {
    regionPath = `/${createSlug(region.country.name)}/${region.slug.replace(
      `-${createSlug(region.country.name)}`,
      ""
    )}`;
  }

  return (
    <Page>
      <TopBar showSearch={true} />
      {region.name && (
        <Section
          style={{
            color: "#fff",
            lineHeight: "30px",
          }}
        >
          {region && (
            <Breadcrumbs>
              <a href={countryPath}>{region.country.name}</a> /
              <a href={regionPath}>
                {region.name.replace(`, ${region.country.name}`, "")}
              </a>
            </Breadcrumbs>
          )}
          <SectionHeading>{region.name}</SectionHeading>
          {region.description && (
            <div>
              <div
                className={clsx("activity-description", {
                  expanded,
                })}
              >
                {region.description}
              </div>
              <Button secondary compact onClick={() => setExpanded(!expanded)}>
                {expanded ? "Read less" : "Read more"}
              </Button>
            </div>
          )}
        </Section>
      )}
      {/* {foodTourExperienceActivities &&
        foodTourExperienceActivities.length > 0 && (
          <Section backgroundColor="#f5f5f5">
            <SectionHeading component="h2">
              {region.name.toUpperCase()} FOOD TOURS & EXPERIENCES
            </SectionHeading>
            <SearchResultCarousel
              slides={foodTourExperienceActivities.map((activity) => (
                <SearchResult compact={true} searchResult={activity} />
              ))}
            />
          </Section>
        )} */}
      <Section backgroundColor="#F1F7FA">
        {regionActivities && (
          <SearchResults
            categories={categories}
            selectedRootCategory={selectedRootCategory}
            setSelectedRootCategory={setSelectedRootCategory}
            filterCategories={filterCategories}
            setFilterCategories={setFilterCategories}
            minPrice={minPrice}
            setMinPrice={setMinPrice}
            maxPrice={maxPrice}
            setMaxPrice={setMaxPrice}
            searchResults={regionActivities}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            loading={loadingCategories || loadingRegionActivities}
          />
        )}
      </Section>
      <Section backgroundColor="#fff">
        <SectionHeading component="h2">
          SEE MORE THINGS TO DO IN {region.country.name.toUpperCase()}
        </SectionHeading>
        <SearchResultCarousel
          slides={
            countryActivities &&
            countryActivities.map((activity) => (
              <SearchResult compact={true} searchResult={activity} />
            ))
          }
        />
      </Section>
      {/* <Section>
        <SectionHeading component="h2">LATEST OFFERS</SectionHeading>
      </Section> */}
      <FeaturedIn />
      <Footer />
    </Page>
  );
};

export default RegionPage;

export const Head = ({ pageContext }) => {
  const { region } = pageContext;
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={`${region.name} | Travel Lingual`}
      description={region.description}
      pathname={location.pathname}
    />
  );
};
